'use client';
import type { Question } from '@features/api/client';
import { richtext } from '@features/shared/utils/richtext';
import QuestionAnswerSelect from './QuestionAnswerSelect';

type QuestionAutocompleteProps = {
	questions: Array<Question>;
	leadText?: string | null | undefined;
};

function QuestionAutocomplete({ questions, leadText }: QuestionAutocompleteProps) {
	return (
		<div className="[&_.question-text]:inline [&_.question-text_p]:inline">
			{questions.map((question, index) => {
				return (
					<>
						{leadText && richtext(leadText + ' ', 'question-text', 'span')}
						{question.text && richtext(question.text, 'question-text', 'span')}
						<QuestionAnswerSelect
							question={question}
							uniqueAnswers={question.ordered_answers}
							initalAnswers={question.ordered_answers}
						/>
						{question.postanswer_text &&
							richtext(question.postanswer_text + ' ', 'question-text', 'span')}
						{question.question_display_type === 'vertical' && (
							<>
								<br />
								<br />
							</>
						)}
					</>
				);
			})}
		</div>
	);
}

export default QuestionAutocomplete;
