import { addToCart, createCart } from '@features/api/shopify';
import type { CartLineAddRequest } from '@features/api/shopify/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { queryKeys } from 'settings/queryKeys';

function useAddToCart() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: Array<CartLineAddRequest>) => {
			let cartId = Cookies.get('cartId');
			let cart = null;

			if (!cartId) {
				cart = await createCart();
				if (!cart) throw new Error('Cart not found');

				cartId = cart.id;
			}

			cart = await addToCart(cartId, data);
			if (!cart) throw new Error('Cart not found');

			return cart;
		},
		onSuccess: (data) => {
			Cookies.set('cartId', data.id);

			queryClient.setQueryData(queryKeys.cart.base, data);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.cart.base });
		},
	});
}

export default useAddToCart;
