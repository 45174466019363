import type { TypedTableBlockValue } from '@features/api/client';
import { richtext } from '@features/shared/utils/richtext';

type TableBlockProps = {
	block: TypedTableBlockValue;
};

function TableBlock({ block }: TableBlockProps) {
	return (
		<div
			className="grid rounded-[6px] border border-b-0 border-r-0 border-solid border-gray-150"
			style={{
				gridTemplateColumns: `repeat(${block.columns.length}, minmax(0, 1fr))`,
			}}>
			{block.columns.map((column, index) => (
				<div
					className="border-b border-r border-solid border-gray-150 bg-gray-100 p-3"
					style={{
						borderTopLeftRadius: index === 0 ? '6px' : 'none',
						borderTopRightRadius: index === block.columns.length - 1 ? '6px' : 'none',
					}}
					key={column.heading}>
					{column.heading}
				</div>
			))}
			{block.rows.map((row, index) =>
				row.values.map((value, i) => (
					<div
						className="border-b border-r border-solid border-gray-150 p-3"
						key={`row-value-${i}`}
						style={{
							borderBottomLeftRadius:
								index === block.rows.length - 1 && i === 0 ? '6px' : 'none',
							borderBottomRightRadius:
								index === block.rows.length - 1 && i === row.values.length - 1
									? '6px'
									: 'none',
						}}>
						{richtext(value)}
					</div>
				))
			)}
		</div>
	);
}

export default TableBlock;
