import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import { useMathJaxTypeset } from '@features/shared/providers/MathJaxProvider';
import { IconCheck, IconX } from '@tabler/icons-react';
import { richtext } from '@features/shared/utils/richtext';
import { cn } from '@features/shared/utils/utils';
import { Flex, Radio, Space } from 'antd';
import { useRef } from 'react';
import { useQuestionGroupStore } from '../Question/QuestionGroupStore';
import QuestionAnswerBlocks from './QuestionAnswerBlocks';
import { choiceLabels } from '@features/backoffice/problems/ui/QuestionAnswer/choiceLabels';

type QuestionAnswerProps = {
	answer: QuestionAnswerType;
	index: number;
};

function QuestionAnswer({ answer, index }: QuestionAnswerProps) {
	const mathjaxTypesetRef = useRef<HTMLDivElement>(null);
	useMathJaxTypeset(mathjaxTypesetRef.current);

	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const setAreAnswersBeingChecked = useQuestionGroupStore((state) => state.setAreAnswersBeingChecked);
	const selectedAnswerChoices = useQuestionGroupStore((state) => state.selectedAnswerChoices);

	const isSelected = selectedAnswerChoices.some((selectedAnswer) => selectedAnswer.answerId === answer.id);

	const content = (
		<div ref={mathjaxTypesetRef}>
			<Flex gap={16} align="center">
				{answer.answer_type === 'answer_choice' && choiceLabels[index]}
				<Flex gap={4} align="center" wrap="wrap">
					{answer.display_prefix}
					{richtext(answer.display_text, '')}
					{answer.display_sufix}
					<QuestionAnswerBlocks blocks={answer.blocks} type={answer.answer_type} />
				</Flex>
			</Flex>
		</div>
	);

	if (answer.answer_type === 'answer_choice') {
		return (
			<div
				className={cn(
					areAnswersBeingChecked &&
						answer.is_correct &&
						'text-green-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:border-green-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:bg-green-600',
					areAnswersBeingChecked &&
						!answer.is_correct &&
						'text-red-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:border-red-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:bg-red-600'
				)}>
				<Radio value={answer.id} onClick={() => setAreAnswersBeingChecked(false)}>
					<Space size="small">
						{content}
						{areAnswersBeingChecked && !answer.is_correct && isSelected && (
							<IconX className="text-red-600" />
						)}
						{areAnswersBeingChecked && answer.is_correct && isSelected && (
							<IconCheck className="text-green-600" />
						)}
					</Space>
				</Radio>
			</div>
		);
	}

	return areAnswersBeingChecked && content;
}

export default QuestionAnswer;
