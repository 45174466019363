'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { IconChevronDown, IconMinus, IconPlus } from '@tabler/icons-react';
import { cn } from '../../../shared/utils/utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
	<AccordionPrimitive.Item
		ref={ref}
		className={cn('flex flex-col items-start justify-start gap-3 px-6 py-7', className)}
		{...props}
	/>
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Header className="w-full cursor-pointer">
		<AccordionPrimitive.Trigger
			ref={ref}
			className={cn(
				'flex w-full items-center justify-between gap-3 transition-all [&[data-state=open]>span>.minus-icon]:block [&[data-state=open]>span>.plus-icon]:hidden',
				className
			)}
			{...props}>
			{children}
			<span className="inline-flex aspect-square h-10 w-10 cursor-pointer items-center justify-center gap-2 whitespace-nowrap text-base font-bold text-primary-foreground text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
				<IconPlus className="text-primary-yellow plus-icon h-8 max-h-8 min-h-8 w-8 min-w-8 max-w-8 transition-transform duration-200" />
				<IconMinus className="text-primary-yellow minus-icon hidden h-8 max-h-8 min-h-8 w-8 min-w-8 max-w-8 transition-transform duration-200" />
			</span>
		</AccordionPrimitive.Trigger>
	</AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Content
		ref={ref}
		className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
		{...props}>
		<div className={cn('pb-4 pt-0', className)}>{children}</div>
	</AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
