'use client';

import { MediaPlayer, MediaPlayerInstance, MediaProvider, Poster } from '@vidstack/react';
import '@vidstack/react/player/styles/base.css';
import dynamic from 'next/dynamic';
import { type ComponentProps, forwardRef } from 'react';

const VideoControlsFull = dynamic(() => import('./VideoControlsFull'), { ssr: false });
const VideoControlsBasic = dynamic(() => import('./VideoControlsBasic'), { ssr: false });

type VideoPlayerProps = Omit<ComponentProps<typeof MediaPlayer>, 'children'> & {
	controlsType?: 'basic' | 'full';
};

const VideoPlayer = forwardRef<MediaPlayerInstance, VideoPlayerProps>(
	({ src, controlsType = 'full', ...rest }, ref) => {
		const controlsMarkup = controlsType === 'full' ? <VideoControlsFull /> : <VideoControlsBasic />;

		return (
			<MediaPlayer
				className="relative aspect-video w-full overflow-hidden rounded-lg bg-slate-900 font-sans text-white ring-media-focus data-[focus]:ring-4"
				title="Sprite Fight"
				crossOrigin
				playsInline
				ref={ref}
				src={src}
				{...rest}>
				<MediaProvider>{/* <Poster src={rest.poster} /> */}</MediaProvider>

				{controlsMarkup}
			</MediaPlayer>
		);
	}
);

export default VideoPlayer;
