import type { QuestionAnswerBlock, QuestionBlock } from '@features/api/client';
import { type ReactNode } from 'react';
import MultipleSizeImageBlock from './MultipleSizeImageBlock';
import { richtext } from '@features/shared/utils/richtext';
import TableBlock from './TableBlock';
import MultipleThemesImageBlock from './MultipleThemesImageBlock';

type QuestionContentBlock = QuestionBlock & QuestionAnswerBlock;
type QuestionContentBlockType = QuestionBlock['type'] & QuestionAnswerBlock['type'];
type ContentBlockMap = {
	[K in QuestionContentBlockType]: (block: Extract<QuestionContentBlock, { type: K }>) => ReactNode;
};

const contentBlockMap: ContentBlockMap = {
	dual_size_image: (block) => <MultipleSizeImageBlock block={block.value} />,
	dual_theme_image: (block) => <MultipleThemesImageBlock block={block.value} />,
	rich_text: (block) => richtext(block.value, '', 'span'),
	table: (block) => <TableBlock block={block.value} />,
};

function ProblemContentBlock({ block }: { block: QuestionContentBlock }) {
	const renderBlock = contentBlockMap[block.type];

	return renderBlock(block as any); //@todo: fix this
}

export default ProblemContentBlock;
