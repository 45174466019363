import type { MultipleSizeImageBlockValue } from '@features/api/client';
import CloudinaryImage from '../ui/CloudinaryImage';
import { cn } from '@features/shared/utils/utils';

type MultipleSizeImageBlockProps = {
	block: MultipleSizeImageBlockValue;
	className?: string;
};

function MultipleSizeImageBlock({ block, className }: MultipleSizeImageBlockProps) {
	if (!block.image.url) return null;

	return (
		<>
			<CloudinaryImage
				className={cn('hidden rounded-md md:block', className)}
				alt={block.image.title}
				width={block.image.width}
				height={block.image.height}
				src={block.image.url}
				sizes="
                    (max-width: 768px) 100vw,
                    (max-width: 1200px) 50vw,
                    33vw
                "
			/>
			{block.image_mobile.url && (
				<CloudinaryImage
					className={cn('rounded-md md:hidden', className)}
					alt={block.image_mobile.title}
					width={block.image_mobile.width}
					height={block.image_mobile.height}
					src={block.image_mobile.url}
					sizes="
                        (max-width: 768px) 100vw,
                        (max-width: 1200px) 50vw,
                        33vw
                    "
				/>
			)}
		</>
	);
}

export default MultipleSizeImageBlock;
