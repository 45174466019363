import type { MultipleThemesImageBlockValue } from '@features/api/client';
import CloudinaryImage from '../ui/CloudinaryImage';
import { cn } from '@features/shared/utils/utils';

type MultipleThemesImageBlockProps = {
	block: MultipleThemesImageBlockValue;
	theme?: 'dark' | 'light';
	className?: string;
};

function MultipleThemesImageBlock({ block, className, theme = 'dark' }: MultipleThemesImageBlockProps) {
	switch (theme) {
		case 'light':
			return (
				block.image_dark.url && (
					<CloudinaryImage
						className={cn('rounded-md', className)}
						alt={block.image_dark.title}
						width={block.image_dark.width}
						height={block.image_dark.height}
						src={block.image_dark.url}
						sizes="
                                (max-width: 768px) 100vw,
                                (max-width: 1200px) 50vw,
                                33vw
                            "
					/>
				)
			);

		case 'dark':
			return (
				block.image_light.url && (
					<CloudinaryImage
						className={cn('rounded-md', className)}
						alt={block.image_light.title}
						width={block.image_light.width}
						height={block.image_light.height}
						src={block.image_light.url}
						sizes="
                                (max-width: 768px) 100vw,
                                (max-width: 1200px) 50vw,
                                33vw
                            "
					/>
				)
			);
		default:
			return null;
	}
}

export default MultipleThemesImageBlock;
