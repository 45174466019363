import productFragment from '../fragments/product';
import seoFragment from '../fragments/seo';

const collectionFragment = /* GraphQL */ `
	fragment collection on Collection {
		handle
		title
		description
		seo {
			...seo
		}
		updatedAt
	}
	${seoFragment}
`;

export const getCollectionQuery = /* GraphQL */ `
	query getCollection($handle: String!) {
		collection(handle: $handle) {
			...collection
		}
	}
	${collectionFragment}
`;

export const getCollectionsQuery = /* GraphQL */ `
	query getCollections {
		collections(first: 100, sortKey: TITLE) {
			edges {
				node {
					...collection
				}
			}
		}
	}
	${collectionFragment}
`;

export const getCollectionByIdProductsQuery = /* GraphQL */ `
	query getCollectionProducts($id: ID!, $sortKey: ProductCollectionSortKeys, $reverse: Boolean) {
		collection(id: $id) {
			products(sortKey: $sortKey, reverse: $reverse, first: 100) {
				edges {
					node {
						...product
					}
				}
			}
		}
	}
	${productFragment}
`;

export const getCollectionByHandleProductsQuery = /* GraphQL */ `
	query getCollectionProducts($handle: String!, $sortKey: ProductCollectionSortKeys, $reverse: Boolean) {
		collectionByHandle(handle: $handle) {
			products(sortKey: $sortKey, reverse: $reverse, first: 100) {
				edges {
					node {
						...product
					}
				}
			}
		}
	}
	${productFragment}
`;
