'use client';

import type { Cart } from '@features/api/shopify/types';
import useGetCart from '../../../../products/data/useGetCart';
import { IconCheck } from '@tabler/icons-react';
import useAddToCart from '../../../../products/data/useAddToCart';
import useRemoveFromCart from '../../../../products/data/useRemoveFromCart';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

type ToggleCartItemButtonProps = {
	initalCart?: Cart;
	children?: ReactNode;
	productVariantId: string;
};

function RemoveButton({
	isLoading,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }) {
	if (isLoading) return <AddButton />;
	return (
		<button
			{...rest}
			className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-primary-yellow bg-primary-yellow text-white shadow-[0_0_10px_0_rgba(0,0,0,0.10)] backdrop-blur-[2px]">
			<IconCheck strokeWidth={3} className="h-6 w-6 text-primary-yellow-foreground" />
		</button>
	);
}

function AddButton({
	isLoading,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }) {
	if (isLoading) return <RemoveButton />;

	return (
		<button
			{...rest}
			className="h-8 w-8 rounded-full border-2 border-solid border-white/50 bg-white/20 text-white shadow-[0_0_10px_0_rgba(0,0,0,0.10)] backdrop-blur-[2px]"></button>
	);
}

function ToggleCartItemButton({ initalCart, productVariantId, children }: ToggleCartItemButtonProps) {
	const { mutate: addToCart, isPending: isAddingToCart } = useAddToCart();
	const { mutate: removeFromCart, isPending: isRemovingFromCart } = useRemoveFromCart();

	const isLoading = isAddingToCart || isRemovingFromCart;

	const { data: cart } = useGetCart({ initialData: initalCart });
	const line = cart?.lines.find((line) => line.merchandise.id === productVariantId);

	if (line)
		return (
			<span
				className="flex cursor-pointer gap-7"
				onClick={() => {
					if (isLoading) return;
					removeFromCart([line.id]);
				}}>
				<RemoveButton isLoading={isRemovingFromCart} />
				{children}
			</span>
		);

	return (
		<span
			className="flex cursor-pointer gap-7"
			onClick={() => {
				if (isLoading) return;

				addToCart([
					{
						quantity: 1,
						merchandiseId: productVariantId,
					},
				]);
			}}>
			<AddButton isLoading={isAddingToCart} />
			{children}
		</span>
	);
}

export default ToggleCartItemButton;
