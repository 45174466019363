'use client';

import type { Cart } from '@features/api/shopify/types';
import { formatMoney } from '@features/shared/utils/formatMoney';
import useGetCart from '../../../../products/data/useGetCart';
import { cn } from '@features/shared/utils/utils';
import { BtnSizeEnum, BtnColorEnum, BtnVariantEnum } from '@features/api/client';
import { Button } from '../../../../shared/ui/Button';
import { IconAlertCircle, IconChevronRight } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import { Alert } from '@features/webshop/shared/ui/Alert';

function ProductCardTotals({ initalCart, cartError }: { initalCart?: Cart; cartError?: any }) {
	const router = useRouter();
	const { data: cart, isRefetching } = useGetCart({ initialData: initalCart });

	const subtotalPrice = cart ? formatMoney(cart?.cost?.subtotalAmount) : null;
	const totalPrice = cart ? formatMoney(cart?.cost?.totalAmount) : null;
	const shouldShowSubtotal = subtotalPrice && subtotalPrice !== totalPrice;

	const hasCart = cart && cart.lines.length > 0;

	return (
		<div className="flex flex-col gap-6">
			<div className={cn('flex items-baseline justify-end gap-4', isRefetching && 'opacity-70')}>
				{shouldShowSubtotal && (
					<span className="text-[44px] font-thin line-through">{subtotalPrice}</span>
				)}
				<span className="text-7xl font-extrablack leading-none">{totalPrice}</span>
			</div>

			{cartError && (
				<Alert variant={'default'} className="flex gap-8">
					<IconAlertCircle />
					<span>Oops,kupnja trenutno nije moguća.</span>
				</Alert>
			)}

			<Button
				size={BtnSizeEnum.Large}
				color={BtnColorEnum.Red}
				variant={BtnVariantEnum.Primary}
				disabled={!hasCart || cartError}
				className="w-full"
				onClick={() => {
					if (!hasCart) return;

					router.push(cart.checkoutUrl);
				}}>
				<span>Kupi prirpeme</span>
				<IconChevronRight />
			</Button>
		</div>
	);
}

export default ProductCardTotals;
