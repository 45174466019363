export default {
	token: {
		fontSize: 16,
		wireframe: false,
		colorBgBase: '#ffffff',
		colorBgElevated: '#ffffff',
		colorBgLayout: '#f5f5f5',
		colorPrimary: '#003FB9',
		colorInfo: '#003FB9',
	},
	components: {
		Menu: {
			fontSize: 20,
			fontSizeLG: 24,
			padding: 20,
			itemHeight: 54,
			iconSize: 20,
			activeBarBorderWidth: 0,
			itemSelectedBg: 'rgb(255, 255, 255)',
			itemBorderRadius: 4,
			itemSelectedColor: 'rgb(0, 0, 0)',
			borderRadius: 4,
			boxShadowSecondary:
				'      0 1px 2px -2px rgba(0, 0, 0, 0.16),      0 3px 6px 0 rgba(0, 0, 0, 0.12),      0 5px 12px 4px rgba(0, 0, 0, 0.09)    ',
			iconMarginInlineEnd: 16,
			subMenuItemBg: 'rgba(0, 0, 0, 0.03)',
		},
		Button: {
			contentFontSize: 18,
			contentFontSizeLG: 20,
			contentFontSizeSM: 15,
			onlyIconSize: 18,
			onlyIconSizeLG: 20,
			onlyIconSizeSM: 16,
			paddingBlock: 8,
			paddingBlockLG: 12,
			paddingInline: 28,
			paddingInlineLG: 32,
			paddingInlineSM: 8,
			controlHeight: 48,
			controlHeightLG: 64,
			controlHeightSM: 34,
			borderRadius: 4,
			borderRadiusSM: 2,
			primaryShadow: '',
			defaultBg: 'rgb(39, 39, 39)',
			defaultColor: 'rgba(255, 255, 255, 0.88)',
			defaultBorderColor: 'rgb(0, 0, 0)',
		},
		Card: {
			borderRadiusLG: 4,
			colorBgContainer: 'rgb(255, 255, 255)',
		},
		Layout: {
			zeroTriggerHeight: 46,
			zeroTriggerWidth: 46,
		},
	},
};
